<template>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <a href="/" class="a-logocontainer">
          <picture class="logo-container">
            <source media="(min-width:1200px)" srcset="../assets/images/logo.png">
            <img src="../assets/images/logo.png" alt="Flowers" width="100%" height="auto">
            
          </picture>
          <!-- <img class="header-content__img" src="img/logo.png" alt="bigapple" /> -->
        </a>
        <!-- <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
          <span class="material-symbols-rounded">menu</span>
        </button> -->
        <menu-component/>
      </div>
      <!-- <div class="header-content__info">
        <ul class="header-content__info--list">
          <li class="header-content__info--list-item">
            <router-link to="sports">sports</router-link>
          </li>
          <li class="header-content__info--list-item">
            <a href="live-betting.php">Live betting</a>
          </li>
          <li class="header-content__info--list-item">
            <a href="casino.php">Casino</a>
          </li>
        </ul>
      </div> -->
      <div class="header-content__cta">
        <!-- <span class="header-content__language material-symbols-rounded">language</span> -->
        <div class="header-buttons">
          <form class="form-login form-desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" placeholder="User" name="account" id="account" required>
            <input type="password" placeholder="Password" name="password" id="password" required>
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
            
          </form>
          <form class="form-login form-mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" placeholder="User" name="account" id="account" required>
            <input type="password" placeholder="Password" name="password" id="password" required>
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
            
          </form>
          <div class="btn-header">
            <button href="#" class="btn-bet secondary-button secondary-button__classic" @click="openModal">BETSLIP</button>
            <a href="https://agents.ofs2k.ag/AgentSiteV2/" target="_blank" class="btn-bet secondary-button secondary-button__agent">AGENT</a>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="modal" />
  </header>
</template>

<script>
  import MenuComponent from '@/components/menu.vue'
  import { defineAsyncComponent } from 'vue'
  const Modal = defineAsyncComponent(()=> import('@/components/modal.vue'))
  export default {
    name: "HeaderComponent",
    components: {
      Modal,
      MenuComponent
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal();
      }
    },
    setup() {
      const DGS_SITEID = 609;
      const backendUrl = "ofs2k.ag";
      return {
        DGS_SITEID,
        backendUrl,
      }
    },
  };
</script>
