<template>
  <footer class="footer">
    <div class="footer-content">
      <ul class="footer-content__list" v-for="(route,key) in routes" :key="key">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in route.colum" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
    </footer>
    <div class="mobile-nav-container">
        <div class="mobile-nav-container-content">
          <router-link class="mobile-nav-items" v-for="(option,index) in options" :key="index" :to="option.route">
            <div class="mobile-nav-img-container"><img :src="option.img" :alt="option.alt" width="100%" height="auto" rel="preload"></div>
            <span>{{option.name}}</span></router-link>
        </div>
    </div>
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      return {
        routes: [
            {
              colum:[
                {
                  name       : 'sportsbook',
                  route      :  'sportsbook'
                },
                {
                  name       : 'racebook',
                  route      :  'racebook'
                },
                {
                  name       : 'live betting',
                  route      :  'live-betting'
                },
                {
                  name       : 'casino',
                  route      :  'casino'
                },
                
              ]
          },
          {
            colum:[
              {
                  name       : 'props builder',
                  route      :  'props-builder'
              },
              {
                  name       : 'house rules',
                  route      :  'house-rules'
              },
              {
                name       : 'sport rules',
                route      :  'sport-rules'
              },
              {
                name       : 'horse rules',
                route      :  'horse-rules'
              },
              {
                route      :  'same-game-parlay-rules',
                name        : 'same game parlay rules'
              }
            ]
          }
          
        ],
        options:[
          {
            route 	   : 'sportsbook',
            img        :  require('../assets/images/sports.png'),
            alt        :  'sportsbook',
            name       :  'SPORTS'
          },
          {
            route 	   : 'live-betting',
            img        :  require('../assets/images/live betting.png'),
            alt        :  'live betting',
            name       :  'LIVE BETTING'
          },
          {
            route 	   : 'casino',
            img        :  require('../assets/images/casino.png'),
            alt        :  'casino',
            name       :  'CASINO'
          },
          {
            route 	   : 'racebook',
            img        :  require('../assets/images/racebook.png'),
            alt        :  'racebook',
            name       :  'RACEBOOK'
          },
          {
            route 	   : 'props-builder',
            img        :  require('../assets/images/props-builder.svg'),
            alt        :  'Props-Builder',
            name       :  'PROPS BUILDER'
          }
        ]
      }
    },
  };
</script>
